export const ArrowRightIconSvg = () => {
    return (
        <svg
            width="6"
            height="11"
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.76658 4.91159L1.28269 0.229789C0.989257 -0.0765962 0.513508 -0.0765962 0.220075 0.229789C-0.0733578 0.536174 -0.0733578 1.03292 0.220075 1.33931L4.20488 5.5L0.220075 9.66069C-0.0733578 9.96708 -0.0733578 10.4638 0.220075 10.7702C0.513508 11.0766 0.989257 11.0766 1.28269 10.7702L5.76658 6.08841C6.07781 5.76344 6.07781 5.23656 5.76658 4.91159Z"
                fill="#222222"
            />
        </svg>
    );
};
